import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';

// components
import { BaseContainer } from '@/components/primitives';
import { BaseButton } from '@/components/partials';
import { ProductList } from '@/components/features';

// types
import type { ComputedRef } from 'vue';
import type { WopsUserInterface } from '@/classes/user';
import type { ProductItem } from '@/types/product';


export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const store = useStore();
const router = useRouter();

const user: ComputedRef<WopsUserInterface> = computed(() => store.state.returnOrder.user);
const toyboxItems: ComputedRef<ProductItem[]> = computed(() => store.state.returnOrder.toyboxItems);

const goToProduct = async (product: ProductItem, message?: string, messageColor?: string): Promise<void> => {
  await store.dispatch('returnOrder/editToyboxItem', product.id);
  if (message) store.dispatch('page/addMessage', message, { root: true });
  if (messageColor) store.dispatch('page/addMessageColor', messageColor, { root: true });
};
const goToUnexpectedItem = (user: WopsUserInterface): void => {
  router.push({ name: 'Unexpected', params: { user_id: user.id as string } });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(BaseContainer), null, {
    default: _withCtx(() => [
      (toyboxItems.value)
        ? (_openBlock(), _createBlock(_unref(ProductList), {
            key: 0,
            products: toyboxItems.value,
            onListItemClicked: goToProduct
          }, null, 8, ["products"]))
        : _createCommentVNode("", true),
      _createVNode(_unref(BaseButton), {
        color: "default-alt",
        ref: "unexpectedItem",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (goToUnexpectedItem(user.value))),
        type: "submit"
      }, {
        default: _withCtx(() => _cache[1] || (_cache[1] = [
          _createTextVNode(" Other item ")
        ])),
        _: 1
      }, 512)
    ]),
    _: 1
  }))
}
}

})